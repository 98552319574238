
import PositionComponent from "./components/Position.vue"
import BackgroundComponent from "./components/Background.vue"
import {
  BedStatus,
  Position,
  RealtimeData,
  RealtimeDataType
} from "./constants"
import { ref, onDeactivated } from "vue"
import { useRoute } from "vue-router"
import { WS_HOST } from "@/assets/script/constant/config.constant"
import { Platform, SUCCESS } from "@/assets/script/constant/main.constant"
import { SignInVo, UserService } from "@/assets/script/service/user.service"
import { Toast } from "vant"
import { sleep } from "@/assets/script/utils/heighten.util"
import {
  Series,
  EquipmentVo,
  EquipmentService
} from "@/assets/script/service/equipment.service"

const REAL_TIME__USER_INFO = "REAL_TIME__USER_INFO"
export default {
  components: {
    PositionComponent: PositionComponent,
    BackgroundComponent: BackgroundComponent
  },
  setup() {
    const router = useRoute()
    const position = ref(Position.LEFT)
    const webSocket = new WebSocket(WS_HOST)
    const left = ref(undefined as RealtimeData | undefined)
    const right = ref(undefined as RealtimeData | undefined)
    const equipment = ref(undefined as EquipmentVo | undefined)
    const userInfo = ref(undefined as SignInVo | undefined)
    webSocket.onmessage = (result) => {
      const data: RealtimeData = JSON.parse(result.data)
      if (data.type === RealtimeDataType.LEFT) {
        left.value = data
      }
      if (data.type === RealtimeDataType.RIGHT) {
        right.value = data
      }
    }

    webSocket.onopen = () => {
      setInterval(() => {
        webSocket.send(JSON.stringify({ method: "pong" }))
      }, 3000)
    }

    const query = router.query

    const sendRealtime = (s = false) => {
      webSocket.send(
        JSON.stringify({
          method: "realtime",
          uid: userInfo.value?.id,
          deviceId: equipment.value?.deviceId,
          switch: s
        })
      )
    }

    const sendBindUser = () => {
      webSocket.send(
        JSON.stringify({
          method: "bindUser",
          uid: userInfo.value?.id,
          deviceType: Platform.MINI_PROGRAM,
          token: userInfo.value!.token
        })
      )
    }

    const listEquipment = async () => {
      const result = await EquipmentService.listByUserId(
        Series.MATTRESS,
        userInfo.value!.token
      )
      if (result.data?.mattressList[0] && result.status === SUCCESS) {
        equipment.value = result.data.mattressList[0]
      } else {
        alert(result.message)
        throw new Error(result.message)
      }
    }

    const signIn = async () => {
      const result = await UserService.signIn(
        query.p1 as string,
        query.p2 as string
      )
      if (result.data && result.status === SUCCESS) {
        userInfo.value = result.data
        localStorage.setItem(REAL_TIME__USER_INFO, JSON.stringify(result.data))
      } else {
        alert(result.message)
        throw new Error(result.message)
      }
    }

    const next = async () => {
      await listEquipment()
      await sleep(1000)
      sendBindUser()
      await sleep(500)
      sendRealtime(true)
    }

    const ready = async () => {
      const toast = Toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner"
      })
      if (query.p1 && query.p2) {
        await signIn()
      } else {
        const userInfoString = localStorage.getItem(REAL_TIME__USER_INFO)
        if (userInfoString) {
          userInfo.value = JSON.parse(userInfoString) as SignInVo | undefined
        } else {
          alert("请传递参数")
          throw new Error("请传递参数")
        }
      }
      await next()
      toast.clear()
    }

    onDeactivated(() => {
      sendRealtime(false)
    })
    ready()
    return {
      Position,
      BedStatus,
      position,
      left,
      right,
      equipment
    }
  }
}
