import httpUtil from "@/assets/script/utils/http.util"
import { Result } from "@/assets/script/model/main.model"

export enum Series {
  MATTRESS = "MATTRESS",
  MPE_CTOB = "MPE_CTOB"
}

export enum WifiStatus {
  OUT,
  ON
}

export interface EquipmentVo {
  deviceId: string
  equipmentSeries: Series
  id: string
  name: string
  status: WifiStatus
}

export class EquipmentService {
  static listByUserId(
    series: Series,
    token: string
  ): Promise<Result<Record<"mattressList" | "bedList", EquipmentVo[]>>> {
    return httpUtil.get("/mpeEquipment/list", {}, { token })
  }
}
