import { Result } from "@/assets/script/model/main.model"
import httpUtil from "@/assets/script/utils/http.util"

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  INCONVENIENT = "INCONVENIENT"
}

export interface UserInfoVo {
  id?: string
  gender: Gender
  birthday: string
  name: string
  phone?: string
  head?: string
  stature: number
  token: string
  weight: number
}

export interface SignInVo extends UserInfoVo {
  token: string
}

export class UserService {
  static signIn(p1: string, p2: string): Promise<Result<SignInVo>> {
    return httpUtil.post("/user/login", { phone: p1, password: p2 }, "form")
  }
}
