
import { Position } from "../constants"
import { SetupContext } from "@vue/runtime-core"

export default {
  props: ["modelValue"],
  setup(props: { modelValue: Position }, context: SetupContext) {
    const change = (p: Position) => {
      context.emit("update:modelValue", p)
    }
    return {
      Position,
      change
    }
  }
}
