<template>
  <my-card class="bg">
    <template #header>
      <div class="pa-4">
        实时环境
        <!--        <div class="absolute" :class="[wifiStatus ? 'online' : 'offline']">-->
        <!--          ●-->
        <!--          {{ wifiStatus ? "在线" : "离线" }}-->
        <!--        </div>-->
      </div>
    </template>
    <my-card-body>
      <div class="pa-6 bg-desc">
        <div class="mt-1 d-flex">
          <div class="d-flex items-center">
            <div class="iconfont iconreport-icon-wendu"></div>
            <div class="ml-2 value">
              {{
                left && left.temp
                  ? left.temp
                  : right && right.temp
                  ? right.temp
                  : "--"
              }}
            </div>
            <div class="unit ml-2">℃</div>
          </div>
          <div class="d-flex items-center ml-6">
            <div class="iconfont iconreport-icon-shidu"></div>
            <div class="ml-2 value">
              {{
                left && left.hum
                  ? left.hum
                  : right && right.hum
                  ? right.hum
                  : "--"
              }}
            </div>
            <div class="unit ml-2">
              %
            </div>
          </div>
        </div>
      </div>
    </my-card-body>
  </my-card>
</template>

<script>
import { getWeek } from "@/assets/script/utils/date.util"

export default {
  props: ["left", "right"],
  setup() {
    return {
      wifiStatus: true,
      weeks: getWeek(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
    }
  }
}
</script>

<style scoped lang="less">
@import "../../../assets/style/color";

.bg {
  .absolute {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 14px;

    &.online {
      color: #38ba00;
    }

    &.offline {
      color: red;
    }
  }

  .bg-desc {
    .weeks {
      font-size: 28px;
      line-height: 28px;
      display: inline-block;
    }

    .desc {
      font-size: xx-small;
      display: inline-block;
      opacity: 0.75;
    }

    .iconfont {
      font-size: 24px;
    }

    .value {
      font-size: 28px;
    }

    .unit {
      font-size: 18px;
      display: inline-block;
      opacity: 0.75;
    }
  }
}
</style>
